
import {
    defineComponent, defineAsyncComponent, computed, PropType,
    watch, ref
} from 'vue';
import { TabsData } from '@/views/distributor/third-party-device/types/common';
import { EventBusType } from '@/util/use-event-bus/types/eventbus';
import { community } from '@/data';
import { isCNServer } from '@/util/location';

export default defineComponent({
    props: {
        build: {
            type: String,
            default: ''
        },
        id: {
            type: String,
            default: ''
        },
        deviceType: {
            type: String,
            default: ''
        },
        uuid: {
            type: String,
            default: ''
        },
        eventBus: {
            type: Object as PropType<EventBusType>
        }
    },
    setup(props) {
        const activeName = ref(props.deviceType);

        const baseTabs: TabsData = {
            control: {
                label: WordList.ControlPanel,
                name: 'control',
                component: defineAsyncComponent(
                    () => import('@/components/view/installer/community/build-content-model/components/user-control-devices.vue')
                )
            },
            camera: {
                label: WordList.Camera,
                name: 'camera',
                component: defineAsyncComponent(
                    () => import('@/components/view/installer/community/build-content-model/components/user-camera-devices.vue')
                )
            }
        };
        const tabsData = computed(() => {
            // 新社区且国内云，显示第三方中控
            const isShowControl = community.isNew.value && isCNServer();
            if (!isShowControl) {
                return { camera: baseTabs.camera };
            }
            return baseTabs;
        });

        watch(tabsData, () => {
            if (props.deviceType === '') {
                [activeName.value] = Object.keys(tabsData.value);
            }
        }, { deep: true, immediate: true });

        const changeTab = (name: any) => {
            activeName.value = name.paneName;
        };

        return {
            tabsData,
            activeName,
            changeTab
        };
    }
});
