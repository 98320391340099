import { ref, Ref } from 'vue';
import { user } from '@/methods/rule';
import { checkMAC } from '@/methods/rules/device';
import { community } from '@/data';

const notAddUser = '0';
const addUser = '1';

const getCallType = () => {
    const enableLandLineCallType = [{
        label: WordList.ProperAllTextSmartPlusIndoor,
        value: '0'
    }, {
        label: WordList.ProperAllTextPhoneIndoor,
        value: '1'
    }, {
        label: WordList.ProperAllTextSmartPlusIndoorBackup,
        value: '2'
    }, {
        label: WordList.ProperAllTextIndoorSmartPlusBackup,
        value: '3'
    }, {
        label: WordList.ProperAllTextIndoorPhoneBackup,
        value: '4'
    }, {
        label: WordList.ProperAllTextIndoorSmartPlusPhone,
        value: '5'
    }];

    const unableLandLineCallType = [{
        label: WordList.ProperAllTextSmartPlusIndoor,
        value: '0'
    }, {
        label: WordList.ProperAllTextIndoorSmartPlusBackup,
        value: '3'
    }];

    if (community.IsEnableLandline.value === 1) {
        return enableLandLineCallType;
    }
    return unableLandLineCallType;
};

const sipCallOptions = [{
    label: WordList.ProperAllTextIPCall,
    value: '1'
}, {
    label: WordList.ProperAllTextSIPCall,
    value: '0'
}];

const initRules = () => ({
    RoomName: [{
        required: true,
        message: WordList.RuleRoomEmpty,
        trigger: 'blur'
    }],
    Floor: [{
        validator: user.checkFloor,
        trigger: 'blur'
    }],
    FirstName:
        [{
            required: true,
            message: WordList.RuleFirstNameEmpty,
            trigger: 'blur'
        }],
    LastName:
        [{
            required: true,
            message: WordList.RuleLastNameEmpty,
            trigger: 'blur'
        }],
    Email:
        [{
            required: true,
            message: WordList.RuleEmailEmpty,
            trigger: 'blur'
        }, {
            validator: user.checkEmail,
            trigger: 'change'
        }],
    MobileNumber:
        [{
            required: true,
            message: WordList.RuleCommonLabelEmpty2.format(WordList.ProperAllTextMobileNumber),
            trigger: 'blur'
        }],
    MAC:
        [{
            required: true,
            message: WordList.RuleMacEmpty,
            trigger: 'blur'
        }, {
            validator: checkMAC,
            trigger: 'blur'
        }],
    Location:
        [{
            required: true,
            message: WordList.RuleLocationEmpty,
            trigger: 'blur'
        }]
});
const createNetwork = () => {
    const networkOptions: Ref<Array<string>> = ref([]);
    const networkLength = 20;
    for (let i = 0; i < networkLength; i += 1) {
        networkOptions.value.push(`${i}`);
    }
    return networkOptions;
};

// webrelayid 0~50选项
const webRelayOptions: {
    value: string;
    label: string;
}[] = [];
for (let i = 0; i < 51; i += 1) {
    webRelayOptions.push({
        value: i.toString(),
        label: i.toString()
    });
}

export default null;
export {
    notAddUser,
    addUser,
    sipCallOptions,
    webRelayOptions,
    initRules,
    createNetwork,
    getCallType
};
