
import {
    defineComponent, defineAsyncComponent, computed,
    ref, watch
} from 'vue';
import { TabsData } from '@/views/distributor/third-party-device/types/common';
import router, { communityBaseRouter, communitySubRouter } from '@/router';
import { community } from '@/data';
import { isCNServer } from '@/util/location';
import { isOffice } from '@/methods/role';

export default defineComponent({
    props: {
        build: {
            type: String,
            default: 'community'
        },
        deviceType: {
            type: String,
            default: ''
        }
    },
    setup(props) {
        const activeName = ref(props.deviceType);
        const baseTabs: TabsData = {
            control: {
                label: WordList.ControlPanel,
                name: 'control',
                component: defineAsyncComponent(
                    () => import('@/components/view/installer/community/build-content-model/components/control-devices.vue')
                )
            },
            camera: {
                label: WordList.Camera,
                name: 'camera',
                component: defineAsyncComponent(
                    () => import('@/components/view/installer/community/build-content-model/components/camera-devices.vue')
                )
            }
        };
        // 判断显示control和camera的条件是否满足
        const tabsData = computed(() => {
            const isShowControl = community.isNew.value && isCNServer() && !isOffice() && props.build === 'community'
                && !community.IsEnableSmartHome.value;
            const isShowCamera = community.thirdPartyPlan.value;
            if (isShowControl && !isShowCamera) {
                return { control: baseTabs.control };
            }
            if (!isShowControl && isShowCamera) {
                return { camera: baseTabs.camera };
            }
            return baseTabs;
        });
        watch(tabsData, () => {
            if (props.deviceType === '') {
                [activeName.value] = Object.keys(tabsData.value);
            }
        }, { deep: true, immediate: true });

        const changeTab = (name: any) => {
            activeName.value = name.paneName;
            router.push(`/${communityBaseRouter}/${communitySubRouter.residentDevice}?type=thirdPartyDevices&Build=${
                props.build}&deviceType=${name.paneName}`);
        };
        return {
            tabsData,
            activeName,
            changeTab
        };
    }
});
