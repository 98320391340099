
import {
    defineComponent,
    Ref,
    ref,
    watch,
    computed
} from 'vue';
import router from '@/router';
import { communitySubRouter, communityBaseRouter } from '@/router/data';
import { useRoute } from 'vue-router';
import { community, account } from '@/data';
import HttpRequest from '@/util/axios.config';
import addButton from '@/components/common/add-button/index.vue';
import addRoomDialog from '@/components/view/installer/community/add-room-dialog';
import peopleSetDialog from '@/components/view/office/people-set-dialog';
import {
    ListActionItem, SearchKey, listPagination, RequestData
} from '@/components/common/list';
import { isOffice } from '@/methods/role';
import { updateSortField, getInitSortField, getSortImg } from '@/methods/sort-table';
import {
    initData, officeAddPeople, getProjectData
} from './user';

export default defineComponent({
    components: {
        listPagination,
        addButton,
        addRoomDialog,
        peopleSetDialog
    },
    props: {
        build: {
            type: String,
            default: 'community'
        },
        DepartmentID: {
            type: String,
            default: 'all'
        }
    },
    setup(props) {
        const {
            identityOptions,
            statusOptions,
            activeOptions,
            headersUser,
            headersRoom,
            formData,
            isShowAddRoomDialog
        } = initData();

        const {
            isShowPeopleDialog,
            peopleData
        } = officeAddPeople();

        const updateToList = ref(0);
        const listRequestData: Ref<RequestData> = ref({
            url: '',
            param: {}
        });
        const searchKeyList: Ref<SearchKey> = ref([]);
        const projectData = getProjectData()[isOffice() ? 'office' : 'community'];
        listRequestData.value = projectData.requestData;
        // 获取初始排序
        const officeTableName = listRequestData.value.param.DepartmentID === 'all' ? 'officePeople' : 'departmentPeople';
        const communityTableName = listRequestData.value.param.Build === 'community' ? 'communityResidents' : 'buildApt';
        const initSortRes = getInitSortField(isOffice() ? officeTableName : communityTableName);
        listRequestData.value.param.SortField = initSortRes.sortField;
        listRequestData.value.param.Sort = initSortRes.sort;
        searchKeyList.value = projectData.searchKeyList;

        const requestBuild = computed(() => {
            if (isOffice()) {
                return useRoute().query ? useRoute().query.DepartmentID : '';
            }
            return useRoute().query ? useRoute().query.Build : '';
        });
        const deleteUrl = computed(() => {
            if (useRoute().query) {
                if (isOffice()) {
                    return 'v3/web/office/user/deleteOfficeUser';
                }
                return useRoute().query.Build === 'community' ? 'v3/web/community/mainUser/delete' : 'v3/web/community/room/delete';
            }
            return '';
        });

        const headers = computed(() => {
            if (useRoute().query) {
                if (isOffice()) {
                    return useRoute().query.DepartmentID === 'all' ? headersUser : headersRoom;
                }
                return useRoute().query.Build === 'community' ? headersUser : headersRoom;
            }
            return [];
        });
        watch(() => [props.build, props.DepartmentID], () => {
            if (props.build !== 'public') {
                let sortRes = {
                    sortField: '',
                    sort: ''
                };
                if (isOffice()) {
                    listRequestData.value.param.DepartmentID = props.DepartmentID === 'all' ? 'all' : props.DepartmentID;
                    // 获取初始排序
                    sortRes = getInitSortField(props.DepartmentID === 'all' ? 'officePeople' : 'departmentPeople');
                } else {
                    listRequestData.value.param.Build = props.build;
                    // 获取初始排序
                    sortRes = getInitSortField(props.build === 'community' ? 'communityResidents' : 'buildApt');
                }
                listRequestData.value.param.SortField = sortRes.sortField;
                listRequestData.value.param.Sort = sortRes.sort;
                updateToList.value += 1;
            } else {
                // 重置选择搜索选项，避免保存住户相关serchkey
                router.push(`/${communityBaseRouter}/${communitySubRouter.residentDevice}?${
                    isOffice() ? 'DepartmentID' : 'Build'}=public&type=device&ID=public&${isOffice() ? 'searchKey' : 'serchKey'}=MAC`);
            }
        });

        // 获取community是否绑定室内机方案
        const getComMonitor = () => {
            HttpRequest.get('v2/getcomindoormonitor', {}, (res: {isComMonitor: number}) => {
                formData.value.IsComMonitor = res.isComMonitor;
            });
        };
        if (!isOffice()) {
            getComMonitor();
        }
        const addRoom = () => {
            if (isOffice()) {
                isShowPeopleDialog.value = true;
            } else {
                getComMonitor();
                formData.value.BuildID = String(isOffice() ? listRequestData.value.param.DepartmentID : listRequestData.value.param.Build);
                isShowAddRoomDialog.value = true;
            }
        };

        const userinfo = (item: {
            ID: string;
        }) => {
            router.push(`/${communityBaseRouter}/${communitySubRouter.residentDevice}?type=userinfo&${
                isOffice() ? 'DepartmentID' : 'Build'}=${requestBuild.value}&id=${item.ID}`);
        };

        // 获取排序方式， 更新表格数据
        function getSortRes(table: string, sortField: string) {
            const {
                sort
            } = updateSortField(table, sortField);
            listRequestData.value.param.SortField = sortField;
            listRequestData.value.param.Sort = sort;
            updateToList.value += 1;
        }

        return {
            statusOptions,
            listRequestData,
            activeOptions,
            searchKeyList,
            updateToList,
            deleteUrl,
            headers,
            requestBuild,
            addRoom,
            formData,
            isShowAddRoomDialog,
            userinfo,
            getComMonitor,
            isShowPeopleDialog,
            peopleData,
            isOffice: isOffice(),
            identityOptions,
            account,
            getSortRes,
            getSortImg
        };
    }
});
