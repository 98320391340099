
import {
    defineComponent, PropType, reactive, watch, ref, computed
} from 'vue';
import { device } from '@/methods/rule';
import { RelayItemForm } from './util';

export default defineComponent({
    emits: ['checkValid', 'changeEnable'],
    props: {
        initForm: {
            type: Object as PropType<RelayItemForm>,
            required: true
        },
        index: {
            type: Number,
            required: true
        },
        showClose: {
            type: Boolean,
            default: false
        },
        showAble: {
            type: Boolean,
            default: false
        },
        showUnlock: {
            type: Boolean,
            default: true
        },
        type: {
            type: String as PropType<'normal' | 'local'>
        },
        commit: {
            type: Number
        },
        relayType: {
            type: String as PropType<'local' | 'security'>,
            default: 'local'
        }
    },
    setup(props, { emit }) {
        const form = reactive({ ...props.initForm });

        const formEle = ref<any>();
        const rules = {
            name: [
                { required: true, message: WordList.RuleRelayEmpty, trigger: 'blur' },
                { validator: device.checkRelayName }
            ]
        };

        watch(computed(() => props.commit), () => {
            if (form.enable) {
                formEle.value.validate((valid: boolean) => {
                    emit('checkValid', props.index, valid, form, props.relayType);
                });
            } else {
                emit('checkValid', props.index, true, form, props.relayType);
            }
        });

        watch(computed(() => form.enable), () => {
            emit('changeEnable', props.index, form.enable, props.relayType);
        }, {
            immediate: true
        });

        return {
            form,
            rules,
            formEle
        };
    }
});
