
import {
    defineComponent,
    PropType,
    reactive,
    Ref,
    ref
} from 'vue';
import { user } from '@/methods/rule';
import { phoneCode, community, systemConfig } from '@/data';
import { isNew } from '@/data/src/community';
import HttpRequest from '@/util/axios.config';
import { isCNServer } from '@/util/location';
import noticeBase from '@/util/notice.base';
import relay from '@/components/view/common/relay';
import { communityApi, residentDeviceApi } from '@/api';
import { labelType } from '@/components/view/common/device-info/util';
import { getPhoneCodeOps } from '@/util/phone-code';
import {
    addUser, notAddUser,
    getCallType,
    sipCallOptions, initRules, createNetwork,
    webRelayOptions
} from './util';
import { UserDataType, LayoutOption } from '../build-content-model/user';

type OperateType = 'add' | 'edit';
export default defineComponent({
    components: {
        relay
    },
    props: {
        operate: {
            type: String as PropType<OperateType>,
            default: 'add'
        },
        initForm: {
            type: Object as PropType<UserDataType>,
            default: () => ({})
        },
        showUser: {
            type: Boolean,
            default: true
        }
    },
    emits: ['close', 'update'],
    setup(props, { emit }) {
        const networkOptions = createNetwork();
        const titles = {
            add: WordList.TabelAptTitleAdd,
            edit: WordList.TabelAptTitleModify
        };
        // 楼层数据
        const floorAreaOptions = Array.from(Array(128).keys(), (n) => ({ value: `${n + 1}`, label: `${n + 1}` }));
        const floorOptions = [{ value: '', label: '' }].concat(floorAreaOptions);
        const accessibleFloorOptions = [{ value: 'all', label: WordList.AllFloors }].concat(floorAreaOptions);

        const formData = reactive({ ...props.initForm });
        console.log('props.initForm,remove', props.initForm);
        const rules = initRules();

        formData.Language = Lang;

        // acc楼层多选限制数量
        const multipleLimitAccFloor = ref('10');
        function multipleAccFloorChange(val: string) {
            if (formData.AccessFloor.length > 0 && (formData.AccessFloor.length > val.length)) {
                return;
            }
            if (val.includes('all') && val.length === 1) {
                multipleLimitAccFloor.value = '1';
            } else if (val.includes('all') && val.length > 1) {
                multipleLimitAccFloor.value = '1';
                formData.AccessFloor = ['all'];
            } else {
                multipleLimitAccFloor.value = '10';
            }
        }
        // 更新AccessFloor
        function updateAccessFloor(val: string[]) {
            if (formData.AccessFloor.length > 0 && (formData.AccessFloor.length > val.length)) {
                return;
            }
            formData.AccessFloor = val;
        }
        // 删除AccessFloor tag
        function removeTag(val: string) {
            const index = formData.AccessFloor.findIndex((item) => item === val);
            if (index > -1) {
                formData.AccessFloor = formData.AccessFloor.filter((item, fIndex) => fIndex !== index);
            }
        }

        // 落地开关限制 呼叫号码输入框 显示
        const EnableLandLine = community.IsEnableLandline;
        const callTypeOptions = getCallType();

        // 初始email、mobile为空，更改校验规则
        if (formData.Email === '') {
            rules.Email = [{
                validator: user.checkEmail,
                trigger: 'change'
            }];
        }
        // if (isNew.value && props.operate === 'edit' && formData.AccessFloor.length > 0) {
        //     rules.AccessFloor = [{
        //         required: true,
        //         message: WordList.RuleCommonLabelEmpty,
        //         trigger: 'blur'
        //     }] as any;
        // }
        if (formData.MobileNumber === '') {
            rules.MobileNumber = [];
        }
        if (isCNServer()) {
            formData.PhoneCode = '86';
        }
        if (formData.IsComMonitor === 0
            || (systemConfig.sysConfig.EnableSmartHome
             && community.IsEnableSmartHome
             && !formData.MAC
             && props.operate === 'edit')) {
            rules.MAC = [];
            rules.Location = [];
        }

        const limitRoomNameType = (val: string) => {
            const reg = /[^\d]]*/g;
            formData.RoomName = val.replace(reg, '');
        };

        const limitMobileNumberType = (val: string) => {
            const reg = /[^\d]]*/g;
            formData.MobileNumber = val.replace(reg, '');
        };

        // 楼层输入限制只能数字或者'-'
        const limitFloorValue = (val: string) => {
            const reg = /[^\d-]*/g;
            formData.Floor = val.replace(reg, '');
        };

        const changeAddUser = (val: string) => {
            formData.IsAddUser = val;
        };

        const form: Ref<any> = ref(null);
        const commit = ref(0);
        const submitRelay = (relayValue?: string) => {
            const { AccessFloor, ...other } = formData;
            const param = { ...other, AccessFloor: isNew ? AccessFloor.join(';') : '' };
            param.Relay = relayValue;
            param.ArmingFunction = param.ArmingFunction ? '1' : '0';
            const url = props.operate === 'add' ? 'addRoom' : 'editRoom';
            param.Active = param.Active ? '1' : '0';
            param.MobileNumber = param.MobileNumber || '';
            // MAC修改时，弹窗提示
            if (param.MAC !== props.initForm.MAC && props.operate === 'edit') {
                noticeBase.messageBox('confirm', WordList.ProperAllTextMACModifyTips,
                    WordList.TabelDeleteTitle, 'warning')(() => {
                    communityApi[url](param, () => {
                        emit('close');
                        emit('update');
                    });
                });
            } else {
                communityApi[url](param, () => {
                    emit('close');
                    emit('update');
                });
            }
        };
        const submit = () => {
            form.value.validate((valid: boolean) => {
                if (valid) {
                    commit.value += 1;
                    // relay隐藏时
                    if (formData.IsComMonitor === 0 || (systemConfig.sysConfig.EnableSmartHome
                        && community.IsEnableSmartHome.value && !formData.MAC)) {
                        submitRelay();
                    }
                }
            });
        };

        const resetPw = () => {
            HttpRequest.post('comresetpw', {
                ID: formData.ID
            }, [(data: { msg: string }) => {
                noticeBase.messageBox('alert', data.msg, WordList.TabelChangePwStepSuccess, 'success')(() => false);
            }, false]);
        };

        // 重置apt
        function resetApt() {
            noticeBase.messageBox(
                'confirm',
                WordList.ResetTips,
                WordList.TabelDeleteTitle,
                'warning'
            )(() => {
                residentDeviceApi.resetRoom({
                    ID: formData.ID
                }, () => {
                    emit('close');
                    emit('update');
                });
            });
        }

        return {
            rules,
            titles,
            formData,
            limitRoomNameType,
            limitMobileNumberType,
            limitFloorValue,
            addUser,
            notAddUser,
            changeAddUser,
            langOptions,
            isCNServer: isCNServer(),
            phoneCode,
            EnableLandLine,
            callTypeOptions,
            sipCallOptions,
            submit,
            form,
            resetPw,
            networkOptions,
            commit,
            submitRelay,
            sysConfig: systemConfig.sysConfig,
            communityEnableSmartHome: community.IsEnableSmartHome,
            webRelayOptions,
            isNew,
            floorOptions,
            accessibleFloorOptions,
            multipleLimitAccFloor,
            multipleAccFloorChange,
            removeTag,
            updateAccessFloor,
            resetApt,
            getPhoneCodeOps
        };
    }
});
