
import {
    defineComponent,
    computed,
    ref,
    watch
} from 'vue';
import router from '@/router';
import { communitySubRouter, communityBaseRouter } from '@/router/data';
import { isOffice } from '@/methods/role';
import { ChangeWordList } from '@/methods/setText';
import { useRoute } from 'vue-router';
import { community } from '@/data';
import { isCNServer } from '@/util/location';
import ThirdPartyDevices
    from '@/components/view/installer/community/build-content-model/components/third-party-devices.vue';
import user from './user.vue';
import userInfo from './userinfo.vue';
import device from './device.vue';
import officePeopleInfo from './office-people-info.vue';

export default defineComponent({
    components: {
        user,
        device,
        userInfo,
        officePeopleInfo,
        ThirdPartyDevices
    },
    props: {
        build: {
            type: String,
            default: 'community'
        },
        DepartmentID: {
            type: String,
            default: 'all'
        },
        deviceType: {
            type: String
        }
    },
    setup(props) {
        const buildType = computed(() => {
            if (isOffice()) {
                return useRoute().query ? useRoute().query.DepartmentID : '';
            }
            return useRoute().query ? useRoute().query.Build : '';
        });

        // 区分住户、设备按钮选择
        const contentType = computed(() => (useRoute().query.type ? useRoute().query.type : 'user'));

        const changeType = (type: 'user' | 'device' | 'thirdPartyDevices') => {
            if (isOffice()) {
                router.push(`/${communityBaseRouter}/${communitySubRouter.residentDevice}?type=${type}&DepartmentID=${buildType.value}`);
            } else if (type === 'thirdPartyDevices') {
                router.push(`/${communityBaseRouter}/${communitySubRouter.residentDevice}?type=${
                    type}&Build=${buildType.value}&deviceType=`);
            } else {
                router.push(`/${communityBaseRouter}/${communitySubRouter.residentDevice}?type=${type}&Build=${buildType.value}`);
            }
        };

        const isShowSmartHome = ref(community.IsEnableSmartHome.value);
        watch(computed(() => community.IsEnableSmartHome.value), () => {
            isShowSmartHome.value = community.IsEnableSmartHome.value;
        });

        // 国内新社区新增第三方设备显示
        const isShowThirdDevice = computed(() => (community.isNew.value && isCNServer() && !isOffice()
            && buildType.value === 'community' && !community.IsEnableSmartHome.value) || community.thirdPartyPlan.value);
        // 第三方设备词条更改
        const deviceText = computed(() => (isShowThirdDevice.value ? WordList.IntercomDevices : WordList.OrderDevice));

        return {
            buildType,
            changeType,
            contentType,
            isOffice: isOffice(),
            ChangeWordList,
            isShowSmartHome,
            isShowThirdDevice,
            deviceText
        };
    }
});
