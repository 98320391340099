import {
    computed, ref, Ref, reactive
    // reactive
} from 'vue';
import { useRoute } from 'vue-router';
import { community } from '@/data';
import remove from '@/methods/remove-func';
import HttpRequest from '@/util/axios.config';
import { ListActionItem } from '@/components/common/list';
import { isCNServer } from '@/util/location';
// import { YaleDeviceType } from '@/components/view/community/yale-device-dialog';
// import router from '@/router';
// import { communityBaseRouter, communitySubRouter } from '@/router/data';
import Notice from '@/util/notice.base';
import { residentDeviceApi } from '@/api';
import { getSubList, getComUserInfo, GetSubList } from '@/api/community/user';
import { getDeviceOwnerList, GetDeviceOwnerList } from '@/api/community/device';
import { EventBusType } from '@/util/use-event-bus/types/eventbus';
import { isNew } from '@/data/src/community';
import { getInitSortField } from '@/methods/sort-table';

const callTypeOptions = [
    WordList.ProperAllTextSmartPlusIndoor,
    WordList.ProperAllTextPhoneIndoor,
    WordList.ProperAllTextSmartPlusIndoorBackup,
    WordList.ProperAllTextIndoorSmartPlusBackup,
    WordList.ProperAllTextIndoorPhoneBackup,
    WordList.ProperAllTextIndoorSmartPlusPhone
];
const sipIpCallOptions = [
    WordList.ProperAllTextSIPCall,
    WordList.ProperAllTextIPCall
];

const activeOptions = [
    WordList.ProperAllTextInactive,
    WordList.ProperAllTextNormal,
    WordList.ProperAllTextExpired
];

const initOptions = [
    WordList.DarshbordUnregistered,
    WordList.DarshbordRegistered
];
interface UserDataType {
    ID: string;
    BuildID: string;
    UnitID: string;
    RoomNumber: string;
    RoomName: string;
    IsAddUser: string;
    FirstName: string;
    LastName: string;
    Email: string;
    MobileNumber: string;
    Phone: string;
    Phone2: string;
    Phone3: string;
    PhoneCode: string;
    CallType: string;
    Active: boolean | string;
    EnableIpDirect: string;
    Key: string;
    Special?: string;
    Name?: string;
    Initialization?: string;
    PKey?: string;
    Layout?: string;
    IsComMonitor: number;
    MAC: string;
    ArmingFunction: string | boolean;
    NetGroupNumber: string;
    Location: string;
    Relay?: string;
    NodeID?: string;
    WebRelayID: string;
    devicesSpecial: string;
    Floor: string;
    AccessFloor: string[];
    ActiveName: string;
}

interface SubUserType {
    ID: string;
    FirstName: string;
    LastName: string;
    Email: string;
    MobileNumber: string;
    Phone: string;
    PhoneCode: string;
    MainUserEmail: string;
    AccessFloor: string[];
}

interface UserDeviceType {
    Type: string;
    MAC: string;
    Location: string;
    NetGroupNumber: string;
    StairShow: string;
    NodeID: string;
    isPublic: boolean;
    Relay: string;
    RelayName1: string;
    RelayName2: string;
    RelayName3: string;
    ArmingFunction: boolean | string;
    Special: string;
    SecurityRelay?: string;
}

type indexType = 'Name' | 'Email' | 'MobileNumber' | 'Phone' | 'CallType'
| 'Active' | 'Initialization' | 'WebRelayID' | 'EnableIpDirect'|'AccessFloor';
// 迁移所以先使用这种方法
const familyInfoHasAccFloors = ref([{
    name: 'Name' as indexType,
    title: WordList.PersonuserFamilyMaster,
    content: ''
}, {
    name: 'Email' as indexType,
    title: WordList.TabelPropertyManageEmail,
    content: ''
}, {
    name: 'MobileNumber' as indexType,
    title: WordList.ProperAllTextMobileNumber,
    content: ''
}, {
    name: 'Phone' as indexType,
    title: WordList.ProperAllTextLandline,
    content: ''
}, {
    name: 'Active' as indexType,
    title: WordList.RDeviceListTanleActive,
    content: '',
    options: activeOptions
}, {
    name: 'Initialization' as indexType,
    title: WordList.ProperAllTextAppStatus,
    content: '',
    options: initOptions
}, {
    name: 'AccessFloor' as indexType,
    title: WordList.AccessFloor,
    content: '',
    options: ''
}]);
const familyInfoList = ref([{
    name: 'Name' as indexType,
    title: WordList.PersonuserFamilyMaster,
    content: ''
}, {
    name: 'Email' as indexType,
    title: WordList.TabelPropertyManageEmail,
    content: ''
}, {
    name: 'MobileNumber' as indexType,
    title: WordList.ProperAllTextMobileNumber,
    content: ''
}, {
    name: 'Phone' as indexType,
    title: WordList.ProperAllTextLandline,
    content: ''
}, {
    name: 'Active' as indexType,
    title: WordList.RDeviceListTanleActive,
    content: '',
    options: activeOptions
}, {
    name: 'Initialization' as indexType,
    title: WordList.ProperAllTextAppStatus,
    content: '',
    options: initOptions
}]);
// eslint-disable-next-line import/no-mutable-exports
const familyInfo = ref<any>([]);

const requestHeaders = () => {
    const deviceHeaders = [{
        name: 'MAC',
        label: WordList.TabelPersonDeviceInHtmlMAC
    }, {
        name: 'Location',
        label: WordList.TabelPersonDeviceInHtmlLocation,
        headerType: 'customize'
    }, {
        name: 'Type',
        label: WordList.TabelPersonDeviceInHtmlDeviceTypeLabel,
        type: 'customize'
    }, {
        name: 'Status',
        label: WordList.MulListUpdateListTanleStatus,
        type: 'customize'
    }, {
        name: 'CreateTime',
        label: WordList.ProperAllTextBindTime,
        headerType: 'customize'
    }];
    const userHeaders = [{
        name: 'Name',
        label: WordList.TabelPersonUserInHtmlName
    }, {
        name: 'MobileNumber',
        label: WordList.ProperAllTextMobileNumber
    }];
    if (!isCNServer()) {
        userHeaders.splice(1, 0, {
            name: 'Email',
            label: WordList.TabelPropertyManageEmail
        });
    }
    return {
        deviceHeaders,
        userHeaders
    };
};

// const Owner = ref('');
const getUserInfo = (
    eventBus: EventBusType
) => {
    const updateList = ref(0);
    const id = computed(() => (useRoute().query ? useRoute().query.id : ''));
    const deviceType = computed(() => (useRoute().query ? useRoute().query.deviceType : ''));
    const details: Ref<UserDataType> = ref({} as UserDataType);
    const devices = ref<GetDeviceOwnerList[]>([]);
    const subUsers = ref<GetSubList[]>([]);
    const spDevice = ref<GetDeviceOwnerList>({} as GetDeviceOwnerList);
    const showFamilyMembers: Ref<boolean> = ref(false);

    const payUserID = ref('');
    const mainID = ref('');
    const isShowPay = ref(false);
    const operaType = ref('add');

    const getInfo = (tempDetails: UserDataType) => {
        familyInfoList.value.forEach((item, index) => {
            if (item.name === 'Phone') {
                familyInfoList.value[index].content = `${tempDetails.Phone !== '' ? `+${tempDetails.PhoneCode}${tempDetails.Phone},` : ''}
                ${tempDetails.Phone2 !== '' ? `+${tempDetails.PhoneCode}${tempDetails.Phone2},` : ''}
                ${tempDetails.Phone3 !== '' ? `+${tempDetails.PhoneCode}${tempDetails.Phone3},` : ''}`;
            } else if (item.name === 'AccessFloor') {
                const result = (tempDetails.AccessFloor as unknown as string).split(';').join(', ');
                if (result === 'all') {
                    familyInfoList.value[index].content = WordList.AllFloors;
                } else {
                    familyInfoList.value[index].content = result;
                }
            } else if (item.options) {
                console.log('tempDetails[item.name', item.name, tempDetails[item.name]);
                familyInfoList.value[index].content = item.options[Number(tempDetails[item.name])];
            } else {
                familyInfoList.value[index].content = tempDetails[item.name] as string;
            }
        });
    };
    // 旧小区不显示AccessFloor
    const getInfoAccessFloor = (tempDetails: UserDataType) => {
        familyInfoHasAccFloors.value.forEach((item, index) => {
            if (item.name === 'Phone') {
                familyInfoHasAccFloors.value[index].content = `${tempDetails.Phone !== '' ? `+${tempDetails.PhoneCode}${tempDetails.Phone},` : ''}
              ${tempDetails.Phone2 !== '' ? `+${tempDetails.PhoneCode}${tempDetails.Phone2},` : ''}
              ${tempDetails.Phone3 !== '' ? `+${tempDetails.PhoneCode}${tempDetails.Phone3},` : ''}`;
            } else if (item.name === 'AccessFloor') {
                const result = (tempDetails.AccessFloor as unknown as string).split(';').join(', ');
                if (result === 'all') {
                    familyInfoHasAccFloors.value[index].content = WordList.AllFloors;
                } else {
                    familyInfoHasAccFloors.value[index].content = result;
                }
            } else if (item.name === 'Active') {
                familyInfoHasAccFloors.value[index].content = tempDetails.ActiveName;
            } else if (item.options) {
                familyInfoHasAccFloors.value[index].content = item.options[Number(tempDetails[item.name])];
            } else {
                familyInfoHasAccFloors.value[index].content = tempDetails[item.name] as string;
            }
        });
    };

    // 获取初始排序
    const initIntercomSortRes = getInitSortField('aptInfoIntercomDevice');
    const intercomDeviceParam = reactive({
        ID: id.value,
        SortField: initIntercomSortRes.sortField,
        Sort: initIntercomSortRes.sort
    });
    const refreshUserInfo = () => {
        // 获取社区家庭详情
        getComUserInfo({ ID: id.value }, (res: UserDataType) => {
            details.value = res;
            showFamilyMembers.value = Number(res.Special) === 0;
            // eslint-disable-next-line no-unused-expressions
            isNew.value ? getInfoAccessFloor(res) : getInfo(res);
            console.log('isNew.valueisNew.value', isNew.value);
            familyInfo.value = isNew.value ? familyInfoHasAccFloors.value : familyInfoList.value;
            // 保存特殊室内机的mac号
            const specialMac = res.devicesSpecial;
            // 获取家庭设备
            getDeviceOwnerList({ ...intercomDeviceParam }, (deviceRes: GetDeviceOwnerList[]) => {
                devices.value = deviceRes;
                // 获取特殊室内机
                deviceRes.forEach((item) => {
                    if (item.MAC === specialMac) {
                        spDevice.value = item;
                    }
                });
                updateList.value += 1;
            });
        });
        // 获取家庭成员
        getSubList({ ID: id.value }, (res: GetSubList[]) => {
            subUsers.value = res;
            updateList.value += 1;
        });

        eventBus.emit('thirdPartyRefresh');
    };
    refreshUserInfo();

    const userAction: ListActionItem[] = [{
        type: 'pay',
        event(val: { ID: string }) {
            if (community.ChargeMode.value === 1) {
                Notice.messageBox(
                    'alert',
                    WordList.AdditionalAppPermissionsNotice,
                    WordList.PersonuserInfo,
                    'info'
                )(() => true);
                return;
            }
            payUserID.value = val.ID;
            mainID.value = id.value as string;
            isShowPay.value = true;
        },
        class: 'el-icon-my-pay modify-icon',
        showCondition(val: { Active: string }) {
            return val.Active === '0';
        },
        title: WordList.OrderPay
    }, {
        type: 'edit2',
        event: 'edit',
        class: 'el-icon-my-modify modify-icon',
        title: WordList.TabelConfigInHtmlModify
    }, {
        type: 'delete2',
        event(data: { ID: string }) {
            remove(WordList.TabelDeleteText, () => {
                residentDeviceApi.deleteSubUser({
                    ID: data.ID
                }, () => {
                    refreshUserInfo();
                });
            });
        },
        class: 'el-icon-delete delete-icon',
        title: WordList.TabelConfigInHtmlDelete
    }];

    const deviceAction: ListActionItem[] = [{
        type: 'info2',
        event: 'info',
        class: 'el-icon-my-info info-icon',
        title: WordList.PersonuserInfo
    }, {
        type: 'edit2',
        event: 'edit',
        class: 'el-icon-my-modify modify-icon',
        title: WordList.TabelConfigInHtmlModify
    }, {
        type: 'delete2',
        event(data: { ID: string }) {
            remove(WordList.TabelDeleteText, () => {
                HttpRequest.post('v3/web/community/device/delete', {
                    ID: data.ID
                }, () => {
                    refreshUserInfo();
                });
            });
        },
        class: 'el-icon-delete delete-icon',
        title: WordList.TabelConfigInHtmlDelete,
        showCondition(val: {
            MAC: string;
        }) {
            if (spDevice.value === null) {
                return true;
            }
            return val.MAC !== spDevice.value.MAC;
        }
    }];
    return {
        id,
        details,
        devices,
        subUsers,
        spDevice,
        showFamilyMembers,
        refreshUserInfo,
        updateList,
        payUserID,
        mainID,
        isShowPay,
        userAction,
        operaType,
        deviceAction,
        deviceType,
        intercomDeviceParam
    };
};

export default null;
export {
    getUserInfo,
    familyInfo,
    requestHeaders,
    UserDataType,
    SubUserType,
    UserDeviceType,
    callTypeOptions,
    sipIpCallOptions
    // yaleDevice
};
