import {
    Ref, ref, watch, reactive
} from 'vue';
import { community } from '@/data';
import {
    ListHeaderItem, RequestData, getSearch, SearchKey
} from '@/components/common/list';
import { isOffice } from '@/methods/role';
import { PeopleData } from '@/components/view/office/people-set-dialog';
import router from '@/router';
import { getInitSortField } from '@/methods/sort-table';

interface UserDataType {
    ID?: string;
    BuildID: string;
    RoomNumber: string;
    RoomName: string;
    Floor: string;
    IsAddUser: string;
    FirstName: string;
    LastName: string;
    Email: string;
    MobileNumber: string;
    Phone: string;
    Phone2: string;
    Phone3: string;
    PhoneCode: string;
    CallType: string;
    Active: boolean | string;
    EnableIpDirect: string;
    Language: string;
    Key: string;
    Layout: string;
    LayoutName: string;
    IsComMonitor: number;
    MAC: string;
    ArmingFunction: string | boolean;
    NetGroupNumber: string;
    Location: string;
    Relay?: string;
    WebRelayID: string;
    AccessFloor: string[];
}

interface LayoutOption {
    layout_id: string;
    name: string;
}

const initData = () => {
    const isShowAddRoomDialog = ref(false);
    // 办公身份筛选
    const identityOptions = [{
        value: '30',
        label: WordList.ProperAllTextPersonnel
    }, {
        value: '31',
        label: WordList.OfficeStaff
    }];
    const statusOptions = [{
        label: WordList.DarshbordRegistered,
        value: '1'
    }, {
        label: WordList.DarshbordUnregistered,
        value: '0'
    }];

    const activeOptions = [{
        label: WordList.ProperAllTextNormal,
        value: '1'
    }, {
        label: WordList.ProperAllTextInactive,
        value: '0'
    }, {
        label: WordList.ProperAllTextExpired,
        value: '2'
    }];

    const searchKeyList = [{
        label: WordList.ProperAllTextResident,
        name: 'Name'
    }, {
        label: WordList.TabelPropertyManageEmail,
        name: 'Email'
    }, {
        label: WordList.RDeviceSearchLabelRoomNumber,
        name: 'RoomNumber'
    }, {
        label: WordList.RDeviceSearchLabelRoomName,
        name: 'RoomName'
    }];

    let headersUser: ListHeaderItem[] = [{
        name: 'Name',
        label: WordList.ProperAllTextResident,
        headerType: 'customize'
    }, {
        name: 'Email',
        label: WordList.TabelPropertyManageEmail
    }, {
        name: 'Building',
        label: WordList.DeviceDetailDetailUnitName
    }, {
        name: 'RoomName',
        label: WordList.RDeviceSearchLabelRoomName
    }, {
        name: 'RoomNumber',
        label: WordList.RDeviceSearchLabelRoomNumber
    }, {
        name: 'Active',
        label: WordList.RDeviceListTanleActive
    }, {
        name: 'Status',
        label: WordList.ProperAllTextAppStatus
    }, {
        name: 'CreateTime',
        label: WordList.TabelMessageBoxCreateTime,
        headerType: 'customize'
    }, {
        name: 'ExpireTime',
        label: WordList.TabelExpretimeBoxSpecificTime
    }];

    let headersRoom: ListHeaderItem[] = reactive([{
        name: 'RoomName',
        label: WordList.RDeviceSearchLabelRoomName,
        headerType: 'customize'
    }, {
        name: 'RoomNumber',
        label: WordList.RDeviceSearchLabelRoomNumber,
        headerType: 'customize'
    }, {
        name: 'Name',
        label: WordList.ProperAllTextResident
    }, {
        name: 'Email',
        label: WordList.TabelPropertyManageEmail
    }, {
        name: 'MobileNumber',
        label: WordList.ProperAllTextMobileNumber
    }, {
        name: 'Active',
        label: WordList.RDeviceListTanleActive
    }, {
        name: 'CreateTime',
        label: WordList.TabelMessageBoxCreateTime,
        headerType: 'customize'
    }, {
        name: 'ExpireTime',
        label: `${WordList.TabelExpretimeBoxSpecificTime}(${WordList.NavInHtmlMenuApp})`
    }]);

    const formData: Ref<UserDataType> = ref({
        BuildID: '',
        RoomNumber: '',
        RoomName: '',
        Floor: '',
        IsAddUser: '0',
        FirstName: '',
        LastName: '',
        Email: '',
        MobileNumber: '',
        Phone: '',
        Phone2: '',
        Phone3: '',
        PhoneCode: '',
        CallType: '0',
        Active: false,
        EnableIpDirect: '1',
        Language: '',
        Key: '',
        IsComMonitor: 0,
        MAC: '',
        ArmingFunction: false,
        NetGroupNumber: '0',
        Location: '',
        Relay: '',
        Layout: '',
        LayoutName: '',
        WebRelayID: '0',
        AccessFloor: []
    });
    const officePeopleHeader: Array < ListHeaderItem > = [{
        name: 'Name',
        label: WordList.TabelPersonUserInHtmlName,
        type: 'customize',
        headerType: 'customize'
    }, {
        name: 'EmployeeID',
        label: WordList.ProperAllTextID,
        headerType: 'customize'
    }, {
        name: 'Email',
        label: WordList.TabelPropertyManageEmail
    }, {
        name: 'DepartmentName',
        label: WordList.ProperAllTextDepartment
    }, {
        name: 'ActiveName',
        label: WordList.RDeviceListTanleActive
    }, {
        name: 'Status',
        label: WordList.ProperAllTextAppStatus
    }, {
        name: 'CreateTime',
        label: WordList.TabelMessageBoxCreateTime,
        headerType: 'customize'
    }, {
        name: 'ExpireTime',
        label: `${WordList.TabelExpretimeBoxSpecificTime} (${WordList.NavInHtmlMenuApp})`
    }];
    const departmentPeopleHeader: Array <ListHeaderItem> = [{
        name: 'Name',
        label: WordList.TabelPersonUserInHtmlName,
        type: 'customize',
        headerType: 'customize'
    }, {
        name: 'EmployeeID',
        label: WordList.ProperAllTextID,
        headerType: 'customize'
    }, {
        name: 'Email',
        label: WordList.TabelPropertyManageEmail
    }, {
        name: 'MobileNumber',
        label: WordList.ProperAllTextMobileNumber
    }, {
        name: 'ActiveName',
        label: WordList.RDeviceListTanleActive
    }, {
        name: 'Status',
        label: WordList.ProperAllTextAppStatus
    }, {
        name: 'CreateTime',
        label: WordList.TabelMessageBoxCreateTime,
        headerType: 'customize'
    }, {
        name: 'ExpireTime',
        label: `${WordList.TabelExpretimeBoxSpecificTime}(${WordList.NavInHtmlMenuApp})`
    }];
    if (isOffice()) {
        headersUser = officePeopleHeader;
        headersRoom = departmentPeopleHeader;
    }
    return {
        identityOptions,
        statusOptions,
        activeOptions,
        searchKeyList,
        headersUser,
        headersRoom,
        formData,
        isShowAddRoomDialog
    };
};

const officeAddPeople = () => {
    const isShowPeopleDialog = ref(false);
    const peopleData: Ref< PeopleData > = ref({
        DepartmentID: String(router.currentRoute.value.query.DepartmentID),
        Role: '30',
        FirstName: '',
        LastName: '',
        EmployeeID: '',
        Email: '',
        Language: '',
        PhoneCode: '',
        MobileNumber: '',
        CallType: '0',
        EnableIpDirect: '1',
        HasIntercomAccess: false,
        Phone: '',
        Floor: []
    });
    return {
        isShowPeopleDialog,
        peopleData
    };
};

const getProjectData = () => {
    const communityRequestData: RequestData = {
        url: 'v3/web/community/user/getRoomListByIns',
        param: getSearch({
            Status: 'all',
            Active: 'all',
            searchValue: '',
            searchKey: 'Name',
            Build: 'community'
        })
    };
    const officeRequestData: RequestData = {
        url: 'v3/web/office/user/getOfficeUserList',
        param: getSearch({
            Status: 'all',
            Active: 'all',
            searchValue: '',
            searchKey: 'Name',
            DepartmentID: 'all',
            Role: 'all'
        })
    };

    const communitySearchLKeyList: SearchKey = [{
        label: WordList.ProperAllTextResident,
        name: 'Name'
    }, {
        label: WordList.TabelPropertyManageEmail,
        name: 'Email'
    }, {
        label: WordList.RDeviceSearchLabelRoomNumber,
        name: 'RoomNumber'
    }, {
        label: WordList.RDeviceSearchLabelRoomName,
        name: 'RoomName'
    }];
    const officeSearchKeyList: SearchKey = [{
        label: WordList.TabelPersonUserInHtmlName,
        name: 'Name'
    }, {
        label: WordList.TabelPropertyManageEmail,
        name: 'Email'
    }, {
        label: WordList.ProperAllTextID,
        name: 'EmployeeID'
    }];
    const projectData = {
        community: {
            requestData: communityRequestData,
            searchKeyList: communitySearchLKeyList
        },
        office: {
            requestData: officeRequestData,
            searchKeyList: officeSearchKeyList
        }
    };
    return projectData;
};

export default null;
export {
    initData,
    UserDataType,
    officeAddPeople,
    getProjectData,
    LayoutOption
};
