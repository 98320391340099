import { ref, Ref, computed } from 'vue';

interface RelayItemForm {
    name: string;
    code: string;
    unlockHomePage: string;
    unlockTackPage: string;
    enable: boolean;
}

const relayData = () => {
    const initRelay: Ref<RelayItemForm[]> = ref([]);
    const relayValid: Ref<boolean[]> = ref([]);
    const enabledArray: Ref<boolean[]> = ref([]);
    // 设置relay详情
    const setRelay = (relayArray: Array<string>) => {
        relayValid.value = [];
        initRelay.value = [];
        enabledArray.value = [];
        relayArray.forEach((item) => {
            relayValid.value.push(false);
            const relayItemArray = item.split(',');
            initRelay.value.push({
                name: relayItemArray[1],
                code: relayItemArray[0],
                unlockHomePage: relayItemArray[2],
                unlockTackPage: relayItemArray[3],
                enable: relayItemArray[4] === '1'
            });
            enabledArray.value.push(relayItemArray[4] === '1');
        });
    };

    return {
        initRelay,
        relayValid,
        enabledArray,
        setRelay
    };
};

const securityRelayData = () => {
    const initSecurityRelay: Ref<RelayItemForm[]> = ref([]);
    const enabledSecurityArray: Ref<boolean[]> = ref([]);
    const securityRelayValid: Ref<boolean[]> = ref([]);

    /**
     * @description 设置security relay信息
     * @param relayArray 每项relay的具体配置详情
     * @return void
     */
    const setSecurityRelay = (relayArray: Array<string>) => {
        securityRelayValid.value = [];
        initSecurityRelay.value = [];
        enabledSecurityArray.value = [];
        relayArray.forEach((item) => {
            securityRelayValid.value.push(false);
            const relayItemArray = item.split(',');
            initSecurityRelay.value.push({
                name: relayItemArray[1],
                code: relayItemArray[0],
                unlockHomePage: relayItemArray[2],
                unlockTackPage: relayItemArray[3],
                enable: relayItemArray[4] === '1'
            });
            enabledSecurityArray.value.push(relayItemArray[4] === '1');
        });
    };

    return {
        initSecurityRelay,
        enabledSecurityArray,
        securityRelayValid,
        setSecurityRelay
    };
};

export default null;
export {
    RelayItemForm,
    relayData,
    securityRelayData
};