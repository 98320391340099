
import {
    defineComponent, ref, Ref, computed
} from 'vue';
import HttpRequest from '@/util/axios.config';
import { list } from '@/components/common/list';
import router from '@/router';
import { communityBaseRouter, communitySubRouter } from '@/router/data';
import { device, community } from '@/data';
import payApp from '@/components/view/common/subscription/module/pay/pay-app.vue';
import deviceSetDialog from '@/components/view/installer/device-set-dialog';
import { tipsDialog as paymentTipsDialog } from '@/components/view/common/payment/index';
import familymemberDialog, { ResData as AddSubUserResType } from '@/components/view/installer/single/family-member-dialog';
import addButton from '@/components/common/add-button/index.vue';
import addRoomDialog from '@/components/view/installer/community/add-room-dialog';
import remove from '@/methods/remove-func';
import { isCNServer } from '@/util/location';
import { isOffice } from '@/methods/role';
import { residentDeviceApi } from '@/api';
import createSubCallback from '@/methods/createSubCallback';
import UserInfoThirdPartyDevices
    from '@/components/view/installer/community/build-content-model/components/user-info-third-party-devices.vue';
import { useEventBus } from '@/util/use-event-bus';
import { updateSortField, getSortImg } from '@/methods/sort-table';
import {
    getUserInfo, familyInfo,
    requestHeaders,
    UserDataType, SubUserType, UserDeviceType,
    callTypeOptions,
    sipIpCallOptions
} from './userinfo';
import { DeviceTypeIcon } from './device';

export default defineComponent({
    components: {
        addButton,
        list,
        addRoomDialog,
        familymemberDialog,
        payApp,
        paymentTipsDialog,
        deviceSetDialog,
        UserInfoThirdPartyDevices
    },
    setup() {
        const eventBus = useEventBus();

        const isShowAddRoomDialog = ref(false);
        const isShowAddSubUserDialog = ref(false);
        const isShowDeviceDialog = ref(false);
        const isShowTips = ref(false);
        const isEnableLandline = community.IsEnableLandline;
        // 控制web relay id显示
        const { isNew } = community;

        const {
            id,
            details,
            devices,
            subUsers,
            spDevice,
            showFamilyMembers,
            refreshUserInfo,
            updateList,
            payUserID,
            mainID,
            isShowPay,
            userAction,
            operaType,
            deviceAction,
            deviceType,
            intercomDeviceParam
        } = getUserInfo(eventBus);

        const { userHeaders, deviceHeaders } = requestHeaders();
        const formData: Ref<UserDataType> = ref({} as UserDataType);
        // 获取community是否绑定室内机方案
        const getComMonitor = () => {
            HttpRequest.get('v2/getcomindoormonitor', {}, (res: {isComMonitor: number}) => {
                formData.value.IsComMonitor = res.isComMonitor;
            });
        };
        if (!isOffice()) {
            getComMonitor();
        }
        const modifyUser = () => {
            getComMonitor();
            isShowAddRoomDialog.value = true;
            const data = details.value;
            formData.value.ID = data.ID;
            formData.value.BuildID = data.UnitID;
            formData.value.RoomNumber = data.RoomNumber;
            formData.value.RoomName = data.RoomName;
            formData.value.IsAddUser = data.Special === '0' ? '1' : '0';
            formData.value.FirstName = data.FirstName || '';
            formData.value.LastName = data.LastName || '';
            formData.value.Email = data.Email || '';
            formData.value.Phone = data.Phone || '';
            formData.value.Phone2 = data.Phone2 || '';
            formData.value.Phone3 = data.Phone3 || '';
            formData.value.PhoneCode = data.PhoneCode || '';
            formData.value.CallType = data.CallType || '0';
            formData.value.Active = data.Active === '1';
            formData.value.EnableIpDirect = data.EnableIpDirect;
            formData.value.Key = data.PKey || '';
            formData.value.MobileNumber = data.MobileNumber || '';
            formData.value.ArmingFunction = false;
            formData.value.NetGroupNumber = '0';
            formData.value.Layout = data.Layout;
            formData.value.WebRelayID = data.WebRelayID;
            formData.value.Floor = data.Floor;
            const AccessFloorArr = isNew.value ? (data.AccessFloor as unknown as string).split(';') : [];
            formData.value.AccessFloor = AccessFloorArr[0] ? AccessFloorArr : [];
            // formData.value.AccessFloor = [];
            if (formData.value.IsComMonitor === 1 && spDevice.value != null) {
                // Éè±¸ID
                formData.value.NodeID = spDevice.value.ID;
                formData.value.MAC = spDevice.value.MAC;
                formData.value.ArmingFunction = spDevice.value.ArmingFunction === '1';
                formData.value.NetGroupNumber = spDevice.value.NetGroupNumber;
                formData.value.Location = spDevice.value.Location;
                formData.value.Relay = spDevice.value.Relay;
            }
        };

        const subUserInitData = {
            ID: id.value as string,
            FirstName: '',
            LastName: '',
            Email: '',
            MobileNumber: '',
            Phone: '',
            PhoneCode: isCNServer() ? '86' : '',
            MainUserEmail: '',
            // 要迁移到新框架了，所以as
            AccessFloor: [] as any
        };

        const subUserForm = ref(subUserInitData);
        const addFamilyMember = () => {
            subUserForm.value = subUserInitData;
            operaType.value = 'add';
            isShowAddSubUserDialog.value = true;
        };

        const initDeviceData: Ref<UserDeviceType> = ref({} as UserDeviceType);
        const addDevice = () => {
            operaType.value = 'add';
            initDeviceData.value = {
                Type: device.indoor,
                MAC: '',
                Location: '',
                NetGroupNumber: '0',
                StairShow: '1',
                NodeID: id.value as string,
                isPublic: false,
                Relay: '',
                RelayName1: '',
                RelayName2: '',
                RelayName3: '',
                ArmingFunction: false,
                Special: '0'
            };
            // 只在6.1新社区后 有security relay的配置
            if (community.isNew.value) {
                initDeviceData.value.SecurityRelay = '';
            }
            isShowDeviceDialog.value = true;
        };

        const isShowPayNow = () => {
            refreshUserInfo();
            isShowTips.value = false;
            isShowPay.value = true;
        };

        const noticeType = ref('');
        const noticeMainCode = ref();
        const addUserSuccess = (res: AddSubUserResType) => {
            isShowTips.value = false;
            refreshUserInfo();
            createSubCallback(
                res,
                (type: 'main'|'sub'|'') => {
                    noticeMainCode.value = res.TipCode;
                    noticeType.value = type;
                    mainID.value = id.value as string;
                    payUserID.value = res.ID;
                    isShowTips.value = true;
                }
            );
            isShowAddSubUserDialog.value = false;
        };

        const closePayTips = () => {
            isShowTips.value = false;
            refreshUserInfo();
        };

        const editUser = (item: SubUserType) => {
            const tempItem = item;
            if (isNew.value) {
                // (item.AccessFloor as unknown as string).split(';')
                if (typeof item.AccessFloor === 'string') {
                    tempItem.AccessFloor = (item.AccessFloor as unknown as string).split(';');
                    if (tempItem.AccessFloor[0] === '') {
                        tempItem.AccessFloor = [];
                    }
                } else {
                    tempItem.AccessFloor = item.AccessFloor;
                }
            } else {
                tempItem.AccessFloor = [];
            }

            subUserForm.value = tempItem;
            subUserForm.value.MainUserEmail = familyInfo.value[1].content;
            operaType.value = 'edit';
            isShowAddSubUserDialog.value = true;
        };
        const editDevice = (item: UserDeviceType) => {
            operaType.value = 'edit';
            initDeviceData.value = item;
            initDeviceData.value.ArmingFunction = item.ArmingFunction === '1';
            if (spDevice.value !== null) {
                initDeviceData.value.Special = item.MAC === spDevice.value.MAC ? '1' : '0';
            } else {
                initDeviceData.value.Special = '0';
            }
            initDeviceData.value.NodeID = id.value as string;
            isShowDeviceDialog.value = true;
        };
        const infoDevice = (item: object & {
            Owner: string;
            Name: string;
        }) => {
            const detail = item;
            detail.Owner = item.Name;
            localStorage.setItem('detail', JSON.stringify(detail));
            router.push(`/${communityBaseRouter}/${communitySubRouter.devicedetail}/`);
        };

        const deleteUser = () => {
            remove(WordList.TabelDeleteText, () => {
                residentDeviceApi.deleteMainUser({
                    ID: id.value as string
                }, () => {
                    refreshUserInfo();
                });
            });
        };

        const thirdPartyDeviceCount = ref(0);

        const isShowThirdDevice = computed(() => (community.isNew.value && isCNServer() && !isOffice() && !community.IsEnableSmartHome.value)
            || community.thirdPartyPlan.value);

        // 获取排序方式, 更新表格数据
        function getSortRes(table: string, sortField: string) {
            const {
                sort
            } = updateSortField(table, sortField);
            intercomDeviceParam.Sort = sort;
            intercomDeviceParam.SortField = sortField;
            refreshUserInfo();
        }
        return {
            details,
            devices,
            subUsers,
            spDevice,
            showFamilyMembers,
            familyInfo,
            updateList,
            userHeaders,
            deviceHeaders,
            modifyUser,
            formData,
            isShowAddRoomDialog,
            refreshUserInfo,
            isShowAddSubUserDialog,
            addFamilyMember,
            addUserSuccess,
            payUserID,
            mainID,
            isShowPay,
            subUserForm,
            isShowTips,
            closePayTips,
            userAction,
            editUser,
            operaType,
            editDevice,
            infoDevice,
            deviceAction,
            addDevice,
            isShowDeviceDialog,
            initDeviceData,
            DeviceTypeIcon,
            device,
            deleteUser,
            isEnableLandline,
            getComMonitor,
            isShowPayNow,
            isEnableSmartHome: community.IsEnableSmartHome,
            callTypeOptions,
            sipIpCallOptions,
            isNew,
            noticeType,
            noticeMainCode,
            id,
            deviceType,
            thirdPartyDeviceCount,
            eventBus,
            isShowThirdDevice,
            getSortRes,
            getSortImg
        };
    }
});
