
import {
    defineComponent,
    ref,
    Ref,
    computed
} from 'vue';
import { device, community } from '@/data';
import addButton from '@/components/common/add-button/index.vue';
import deviceSetDialog from '@/components/view/installer/device-set-dialog';
import router from '@/router';
import { communityBaseRouter, communitySubRouter } from '@/router/data';
import { isOffice } from '@/methods/role';
import {
    RequestData, listPagination, ListActionItem,
    SearchKey,
    ListHeaderItem
} from '@/components/common/list';
import remove from '@/methods/remove-func';
import HttpRequest from '@/util/axios.config';
import autopDialog from '@/components/view/common/dialog/autop';
import { updateSortField, getInitSortField, getSortImg } from '@/methods/sort-table';
import {
    initData, DeviceTypeIcon, DeviceInfoType,
    getProjectData
} from './device';

export default defineComponent({
    components: {
        listPagination,
        deviceSetDialog,
        addButton,
        autopDialog
    },
    setup() {
        const {
            initDeviceData,
            updateToList,
            operaType,
            buildType
        } = initData();

        const requestData: Ref<RequestData> = ref({
            url: '',
            param: {}
        });
        const projectData = getProjectData()[isOffice() ? 'office' : 'community'];
        const searchKeyList: Ref<SearchKey> = ref([]);
        const headers: Ref<ListHeaderItem[]> = ref([]);
        requestData.value = projectData.requestData;
        searchKeyList.value = projectData.searchKeyList;
        headers.value = projectData.header;

        const isShowDeviceDialog = ref(false);
        const editDevice = (item: DeviceInfoType) => {
            operaType.value = 'edit';
            let tempRelay = item.Relay;
            // 室内机默认两个relay
            if (item.Type === device.indoor && item.Relay === '') {
                tempRelay = `#,${WordList.ProperAllTextRelay}1,1,1,0;#,${WordList.ProperAllTextRelay}2,1,1,0`;
            }
            // eslint-disable-next-line no-nested-ternary
            const build = ((buildType.value === (isOffice() ? 'all' : 'community')) && item.Grade === device.communityShare)
                ? 'public' : (item.Grade === device.unitShare ? item.UnitID : buildType.value);
            initDeviceData.value = {
                Type: item.Type,
                MAC: item.MAC,
                Location: item.Location,
                Build: build as string,
                ID: item.ID,
                NetGroupNumber: item.NetGroupNumber,
                StairShow: item.StairShow,
                isPublic: item.Grade === device.unitShare,
                Relay: tempRelay,
                ArmingFunction: item.ArmingFunction === device.appShowEntry,
                IsAllBuild: (isOffice() ? item.IsAllDepartment : item.IsAllBuild) === device.allBuild,
                Builds: isOffice() ? item.Departments : item.Builds,
                Special: item.Special,
                NodeID: item.NodeID
            };
            // 6.1新社区后，编辑时判断原本是否带有security配置
            if (community.isNew.value) {
                initDeviceData.value.SecurityRelay = item.SecurityRelay ? item.SecurityRelay : '';
            }
            isShowDeviceDialog.value = true;
        };
        const addDevice = () => {
            initDeviceData.value = {
                Type: '0',
                MAC: '',
                Location: '',
                Build: buildType.value as string,
                NetGroupNumber: '0',
                StairShow: '1',
                isPublic: !(buildType.value === 'public'),
                Relay: '',
                RelayName1: '',
                RelayName2: '',
                RelayName3: '',
                ArmingFunction: false,
                IsAllBuild: true,
                Builds: []
            };
            operaType.value = 'add';
            // 只在6.1新社区后 有security relay的配置
            if (community.isNew.value) {
                initDeviceData.value.SecurityRelay = '';
            }
            isShowDeviceDialog.value = true;
        };

        const infoDetail = (item: object & {
            Owner: string;
            Name: string;
        }) => {
            const detail = item;
            detail.Owner = item.Name;
            localStorage.setItem('detail', JSON.stringify(detail));
            router.push(`/${communityBaseRouter}/${communitySubRouter.devicedetail}?type=${isOffice() ? 'office' : 'community'}`);
        };

        const deleteAction: ListActionItem[] = [{
            type: 'info2',
            event: 'info',
            class: 'el-icon-my-info info-icon',
            title: WordList.PersonuserInfo
        }, {
            type: 'edit2',
            event: 'edit',
            class: 'el-icon-my-modify modify-icon',
            title: WordList.TabelConfigInHtmlModify
        }, {
            type: 'delete2',
            event(data: { ID: string }) {
                remove(WordList.TabelDeleteText, () => {
                    HttpRequest.post(isOffice() ? 'v3/web/office/device/delete' : 'v3/web/community/device/delete', {
                        ID: [data.ID]
                    }, () => {
                        updateToList.value += 1;
                    });
                });
            },
            class: 'el-icon-delete delete-icon',
            // 室内机方案设备删除隐藏
            showCondition(val) {
                if (isOffice()) {
                    return true;
                }
                return val.Special === '0';
            },
            title: WordList.TabelConfigInHtmlDelete
        }];

        const isShowAutopDialog = ref(false);

        // 获取排序表格名称
        const sortTableName = computed(() => {
            if (isOffice()) {
                if (buildType.value === 'all') {
                    return 'officeDevice';
                }
                if (buildType.value === 'public') {
                    return 'officePublicDevice';
                }
                return 'officeDepartmentDevice';
            }
            if (buildType.value === 'community') {
                return 'communityIntercomDevice';
            }
            if (buildType.value === 'public') {
                return 'pubicIntercomDevice';
            }
            return 'buildIntercomDevice';
        });

        // 获取初始排序
        const initSortRes = getInitSortField(sortTableName.value);
        requestData.value.param.SortField = initSortRes.sortField;
        requestData.value.param.Sort = initSortRes.sort;
        // 获取排序方式, 更新表格数据
        function getSortRes(table: string, sortField: string) {
            const { sort } = updateSortField(table, sortField);
            requestData.value.param.SortField = sortField;
            requestData.value.param.Sort = sort;
            updateToList.value += 1;
        }
        return {
            searchKeyList,
            requestData,
            updateToList,
            headers,
            DeviceTypeIcon,
            device,
            editDevice,
            isShowDeviceDialog,
            initDeviceData,
            buildType,
            addDevice,
            operaType,
            infoDetail,
            deleteAction,
            isOffice: isOffice(),
            isShowAutopDialog,
            getSortImg,
            getSortRes,
            sortTableName
        };
    }
});
