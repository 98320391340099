import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_relay_item = _resolveComponent("relay-item")
  const _component_cus_button = _resolveComponent("cus-button")

  return (_openBlock(), _createBlock("div", null, [
    (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.initRelay, (item, index) => {
      return (_openBlock(), _createBlock(_component_relay_item, {
        key: item,
        initForm: item,
        index: index,
        commit: _ctx.commit,
        showAble: _ctx.lastDisabled,
        type: _ctx.type,
        showUnlock: _ctx.showUnlock,
        relayType: "local",
        onCheckValid: _ctx.checkValid,
        onChangeEnable: _ctx.changeEnable
      }, null, 8, ["initForm", "index", "commit", "showAble", "type", "showUnlock", "onCheckValid", "onChangeEnable"]))
    }), 128)),
    (_ctx.type==='normal' && _ctx.securityRelay !== false)
      ? (_openBlock(true), _createBlock(_Fragment, { key: 0 }, _renderList(_ctx.initSecurityRelay, (item, index) => {
          return (_openBlock(), _createBlock(_component_relay_item, {
            key: item,
            initForm: item,
            index: index,
            commit: _ctx.commit,
            showAble: _ctx.lastDisabled,
            type: _ctx.type,
            showUnlock: _ctx.showUnlock,
            relayType: "security",
            onCheckValid: _ctx.checkValid,
            onChangeEnable: _ctx.changeEnable
          }, null, 8, ["initForm", "index", "commit", "showAble", "type", "showUnlock", "onCheckValid", "onChangeEnable"]))
        }), 128))
      : _createCommentVNode("", true),
    (_ctx.isShowAdd)
      ? (_openBlock(), _createBlock(_component_cus_button, {
          key: 1,
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.add('local'))),
          type: "blue",
          class: "margin-right10px"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$wordList.ProperAllTextAddRelay), 1)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    (_ctx.isShowAddSecurity && _ctx.type !== 'local')
      ? (_openBlock(), _createBlock(_component_cus_button, {
          key: 2,
          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.add('security'))),
          type: "blue"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$wordList.AddSecurityRelay), 1)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ]))
}