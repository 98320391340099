
import {
    computed, defineComponent, PropType, ref, watch
} from 'vue';
import Notice from '@/util/notice.base';
import relayItem from './item.vue';
import { RelayItemForm, relayData, securityRelayData } from './util';

// 初始化relay顺序默认的dtmf码
const dtmfDefaultCode = ['#', '0', '1', '2', '3'];

export default defineComponent({
    components: {
        relayItem
    },
    props: {
        relay: {
            type: String
        },
        securityRelay: {
            type: [String, Boolean],
            default: false
        },
        commit: {
            type: Number,
            required: true
        },
        type: {
            type: String as PropType<'normal' | 'local'>,
            required: true
        },
        showUnlock: {
            type: Boolean,
            default: true
        }
    },
    setup(props, { emit }) {
        // 保存普通relay和security relay项的配置
        const emitRelay: {
            local: RelayItemForm[];
            security: RelayItemForm[];
        } = {
            local: [],
            security: []
        };
        const relayValue = computed(() => (props.relay
                || (props.type === 'normal'
                    ? `${dtmfDefaultCode[0]},${WordList.ProperAllTextRelay}1,1,1,1`
                    : `${dtmfDefaultCode[0]},${WordList.ProperAllTextRelay}1,1,1,0;${dtmfDefaultCode[1]},${WordList.ProperAllTextRelay}2,1,1,0`)));
        const relayArray = ref(relayValue.value.split(';'));

        const {
            initRelay,
            relayValid,
            enabledArray,
            setRelay
        } = relayData();
        setRelay(relayArray.value);

        // 新增security relay数据
        const {
            initSecurityRelay,
            enabledSecurityArray,
            securityRelayValid,
            setSecurityRelay
        } = securityRelayData();
        // 只在6.1之后的社区显示，添加两项后按钮消失
        const isShowAddSecurity = computed(() => (initSecurityRelay.value.length < 2) && props.securityRelay !== false && props.type === 'normal');
        if (props.securityRelay) {
            setSecurityRelay(props.securityRelay.toString().split(';'));
        }

        const isShowAdd = computed(() => (initRelay.value.length < 4 && props.type === 'normal'));
        const add = (type: 'local' | 'security') => {
            if (type === 'local') {
                relayValid.value.push(false);
                initRelay.value.push({
                    name: `${WordList.ProperAllTextRelay}${initRelay.value.length + 1}`,
                    code: `${dtmfDefaultCode[initRelay.value.length]}`,
                    unlockHomePage: '1',
                    unlockTackPage: '1',
                    enable: true
                });
                enabledArray.value.push(true);
            } else if (type === 'security') {
                securityRelayValid.value.push(false);
                initSecurityRelay.value.push({
                    name: `${WordList.SecurityRelay}${initSecurityRelay.value.length + 1}`,
                    code: `${dtmfDefaultCode[initSecurityRelay.value.length]}`,
                    unlockHomePage: '1',
                    unlockTackPage: '1',
                    enable: true
                });
                enabledSecurityArray.value.push(true);
            }
        };

        // 每次提交要重置relay item的验证，不然checkValid会重复提交
        watch(computed(() => props.commit), () => {
            for (let i = 0; i < relayValid.value.length; i += 1) {
                relayValid.value[i] = false;
            }
            for (let i = 0; i < securityRelayValid.value.length; i += 1) {
                securityRelayValid.value[i] = false;
            }
        });

        watch(() => props.type, () => {
            // 修复编辑设备时，切换type没有刷新relay问题
            if (props.type === 'local') {
                relayArray.value = [`${dtmfDefaultCode[0]},${WordList.ProperAllTextRelay}1,1,1,0`,
                    `${dtmfDefaultCode[1]},${WordList.ProperAllTextRelay}2,1,1,0`];
            } else {
                relayArray.value = [`${dtmfDefaultCode[0]},${WordList.ProperAllTextRelay}1,1,1,1`];
            }
            setRelay(relayArray.value);
        });

        const lastDisabled = ref(false);
        const changeEnable = (index: number, enable: boolean, relayType: 'local' | 'security') => {
            if (relayType === 'local') {
                enabledArray.value[index] = enable;
            } else {
                enabledSecurityArray.value[index] = enable;
            }
            let showCount = 0;
            // 普通relay 和 security relay合在一起判断最少选择一项
            enabledArray.value.forEach((item) => {
                if (item) showCount += 1;
            });
            enabledSecurityArray.value.forEach((item) => {
                if (item) showCount += 1;
            });
            lastDisabled.value = showCount < 2 && props.type === 'normal';
        };

        // 是否显示弹窗与是否提交
        let unlockHomePageMessage = false;
        let unlockTackPageMessage = false;
        // let isEmit = false;

        const checkValid = (index: number, valid: boolean, data: RelayItemForm, relayType: 'local' | 'security') => {
            if (relayType === 'local') {
                relayValid.value[index] = valid;
            } else {
                securityRelayValid.value[index] = valid;
            }

            emitRelay[relayType][index] = data;
            let result = true;
            // 保证普通relay 和 security relay都验证完毕
            relayValid.value.forEach((item) => {
                result = item && result;
            });
            if (props.type === 'normal') {
                securityRelayValid.value.forEach((item) => {
                    result = item && result;
                });
            }

            if (result) {
                const relayTemp: string[] = [];
                const relaySecurityTemp: string[] = [];
                // 同时检查普通relay 和 security relay勾选的合法性
                emitRelay.local.forEach((item) => {
                    relayTemp.push(`${item.code},${item.name},${item.unlockHomePage},${item.unlockTackPage},${item.enable ? '1' : '0'}`);
                });
                emitRelay.security.forEach((item) => {
                    relaySecurityTemp.push(`${item.code},${item.name},${item.unlockHomePage},${item.unlockTackPage},${item.enable ? '1' : '0'}`);
                });

                emit('getRelay', relayTemp.join(';'), relaySecurityTemp.join(';'));
            }
            return true;
        };

        return {
            relayValue,
            initRelay,
            isShowAdd,
            add,
            checkValid,
            lastDisabled,
            changeEnable,
            isShowAddSecurity,
            initSecurityRelay
        };
    }
});
