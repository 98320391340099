
import dialogShell from '@/components/common/dialog-shell/index.vue';
import HttpRequest from '@/util/axios.config';
import {
    defineComponent, ref, Ref, PropType, reactive, watch
} from 'vue';
import { device } from '@/data';
import { isNew } from '@/data/src/community';
import { checkMAC } from '@/methods/rules/device';
import relay from '@/components/view/common/relay';
import { singleDeviceType, multiTenantPhone } from '@/data/src/device';
import { ChangeWordList } from '@/methods/setText';
import noticeBase from '@/util/notice.base';
import moveBuild from './moveBuild.vue';
import {
    FormData,
    deviceType,
    IdentityType,
    comDeviceType,
    createNetwork,
    chooseBuildsFunc,
    ChooseBuildType
} from './all.type';

export default defineComponent({
    components: {
        dialogShell,
        relay,
        moveBuild
    },
    props: {
        operaType: {
            type: String as PropType<OperateType>,
            required: true
        },
        initForm: {
            type: Object as PropType<FormData>,
            required: true
        },
        identity: {
            type: String as PropType<IdentityType>,
            default: 'single'
        }
    },
    setup(props, { emit }) {
        const networkOptions = createNetwork();

        const titles = {
            add: WordList.TabelPersonDeviceTitleAdd,
            edit: WordList.TabelPersonDeviceTitleMod
        };
        const deviceEditFormRef: Ref<any> = ref();
        const formData = reactive({ ...props.initForm });

        // 个人设备可以编辑mac
        let disableMac = !formData.Special && props.operaType === 'edit';
        if (props.identity === 'office') {
            disableMac = false;
        }
        const rules = {
            MAC: [{
                required: true,
                message: WordList.RuleMacEmpty,
                trigger: 'blur'
            }, {
                validator: checkMAC,
                trigger: 'blur'
            }],
            Location: [{
                required: true,
                message: WordList.RuleLocationEmpty,
                trigger: 'blur'
            }]
        };
        const urls = {
            single: {
                add: 'v3/web/single/device/add',
                edit: 'v3/web/single/device/edit'
            },
            community: {
                add: 'v3/web/community/device/add',
                edit: 'v3/web/community/device/edit'
            },
            office: {
                add: 'v3/web/office/device/add',
                edit: 'v3/web/office/device/edit'
            }
        };
        const close = () => {
            emit('close');
        };

        const showMoveBuild = ref(formData.Build === 'public' && formData.Type !== '2');
        // 选择设备管理楼栋模块
        const {
            unchooseBuilds,
            chooseBuilds
        } = chooseBuildsFunc(formData.IsAllBuild, formData.Builds, props.identity);
        const changeChooseBuild = (item: Array<ChooseBuildType>) => {
            const tempBuilds: Array<string> = [];
            item.forEach((ele) => {
                tempBuilds.push(ele.key);
            });
            formData.Builds = tempBuilds;
        };
        const submit = (relayValue: string, securityRelayValue: string) => {
            const param = { ...formData };
            param.Relay = relayValue;
            // 6.1社区后、单住户
            if ((props.identity === 'community' && isNew.value) || props.identity === 'single') {
                param.SecurityRelay = securityRelayValue;
            }
            if (props.identity === 'community' || props.identity === 'office') {
                param.ArmingFunction = param.Type === device.indoor && param.ArmingFunction ? '1' : '0';
                param.IsAllBuild = param.IsAllBuild ? 1 : 0;
                param.Builds = !param.Builds ? [] : (param.Builds as Array<string>).join(';');
            }

            // MAC修改时，弹窗提示
            if (param.MAC !== props.initForm.MAC && props.operaType === 'edit') {
                noticeBase.messageBox('confirm', WordList.ProperAllTextMACModifyTips,
                    WordList.TabelDeleteTitle, 'warning')(() => {
                    HttpRequest.post(urls[props.identity][props.operaType], param, () => {
                        emit('success');
                        close();
                    });
                });
            } else {
                HttpRequest.post(urls[props.identity][props.operaType], param, () => {
                    emit('success');
                    close();
                });
            }
        };

        const commit = ref(0);
        const checkValid = () => {
            deviceEditFormRef.value.validate((valid: boolean) => {
                if (valid) {
                    if (formData.Type === device.managementPhone) {
                        submit('', '');
                    } else {
                        // 解决v-show下管理机提交两次的问题
                        commit.value += 1;
                    }
                }
            });
        };
        const deviceOptions = props.identity === 'single' ? deviceType : comDeviceType;
        const showContactSetting = ref((props.identity === 'community' || props.identity === 'office')
                && formData.isPublic && formData.Type === multiTenantPhone);

        // 弹窗内部切换，动态改变楼栋选择和联系人列表设置
        watch(formData, () => {
            showMoveBuild.value = formData.Build === 'public' && formData.Type !== '2';
            showContactSetting.value = (props.identity === 'community' || props.identity === 'office')
                    && formData.isPublic && formData.Type === multiTenantPhone;
        });
        return {
            titles,
            urls,
            deviceOptions,
            formData,
            checkValid,
            submit,
            close,
            deviceEditFormRef,
            rules,
            commit,
            singleDeviceType,
            networkOptions,
            showContactSetting,
            unchooseBuilds,
            chooseBuilds,
            changeChooseBuild,
            showMoveBuild,
            disableMac,
            ChangeWordList,
            isNew
        };
    }
});

