import { ref, Ref, computed } from 'vue';
import { ListHeaderItem, RequestData, getSearch } from '@/components/common/list';
import { useRoute } from 'vue-router';
import { isOffice } from '@/methods/role';

interface DeviceInfoType {
    Type: string;
    MAC: string;
    Location: string;
    Build?: string;
    NetGroupNumber: string;
    StairShow: string;
    isPublic: boolean;
    Relay: string;
    SecurityRelay?: string;
    RelayName1?: string;
    RelayName2?: string;
    RelayName3?: string;
    ArmingFunction: string | boolean;
    IsAllBuild?: number | boolean;
    IsAllDepartment?: number | boolean;
    Builds?: Array<string>;
    Departments?: Array<string>;
    ID?: string;
    Grade?: string;
    Special?: string;
    NodeID?: string | null;
    UnitID?: string;
}

const DeviceTypeIcon: {
    [key in string]: string
} = {
    0: require('@/assets/image/IconDeviceTypeStair.png'),
    1: require('@/assets/image/IconDeviceTypeDoor.png'),
    2: require('@/assets/image/IconDeviceTypeIndoor.png'),
    3: require('@/assets/image/IconDeviceTypeManagement.png'),
    4: require('@/assets/image/IconDeviceTypeWall.png'),
    50: require('@/assets/image/IconDeviceTypeAccess.png')
};

const buildType = computed(() => {
    if (isOffice()) {
        return useRoute().query.DepartmentID ? useRoute().query.DepartmentID : 'all';
    }
    return useRoute().query.Build ? useRoute().query.Build : 'community';
});
const initData = () => {
    const operaType: Ref< OperateType > = ref('edit');
    let headers: ListHeaderItem[] = buildType.value === (isOffice() ? 'all' : 'community') ? [{
        name: 'MAC',
        label: WordList.TabelPersonDeviceInHtmlMAC
    }, {
        name: 'Location',
        label: WordList.TabelPersonDeviceInHtmlLocation
    }, {
        name: 'Name',
        label: WordList.ProperAllTextResident
    }, {
        name: 'RoomName',
        label: WordList.RDeviceSearchLabelRoomName
    }, {
        name: 'UnitName',
        label: WordList.DeviceDetailDetailUnitName
    }, {
        name: 'Type',
        label: WordList.TabelPersonDeviceInHtmlDeviceTypeLabel,
        type: 'customize'
    }, {
        name: 'SipAccount',
        label: WordList.MulListUpdateListTanleAccount
    }, {
        name: 'Status',
        label: WordList.MulListUpdateListTanleStatus,
        type: 'customize'
    }, {
        name: 'CreateTime',
        label: WordList.TabelMessageBoxCreateTime
    }] : [{
        name: 'MAC',
        label: WordList.TabelPersonDeviceInHtmlMAC
    }, {
        name: 'Location',
        label: WordList.TabelPersonDeviceInHtmlLocation
    }, {
        name: 'Type',
        label: WordList.TabelPersonDeviceInHtmlDeviceTypeLabel,
        type: 'customize'
    }, {
        name: 'SipAccount',
        label: WordList.MulListUpdateListTanleAccount
    }, {
        name: 'Status',
        label: WordList.MulListUpdateListTanleStatus,
        type: 'customize'
    }, {
        name: 'CreateTime',
        label: WordList.TabelMessageBoxCreateTime
    }];
    // 办公场景没有room相关
    if (buildType.value === (isOffice() ? 'all' : 'community') && isOffice()) {
        headers = [{
            name: 'MAC',
            label: WordList.TabelPersonDeviceInHtmlMAC
        }, {
            name: 'Location',
            label: WordList.TabelPersonDeviceInHtmlLocation
        }, {
            name: 'UnitName',
            label: WordList.ProperAllTextDepartment
        }, {
            name: 'Name',
            label: WordList.BasicUser
        }, {
            name: 'Type',
            label: WordList.TabelPersonDeviceInHtmlDeviceTypeLabel,
            type: 'customize'
        }, {
            name: 'SipAccount',
            label: WordList.MulListUpdateListTanleAccount
        }, {
            name: 'Status',
            label: WordList.MulListUpdateListTanleStatus,
            type: 'customize'
        }, {
            name: 'CreateTime',
            label: WordList.TabelMessageBoxCreateTime
        }];
    }
    const initDeviceData: Ref<DeviceInfoType> = ref({
        Type: '0',
        MAC: '',
        Location: '',
        Build: 'community',
        NetGroupNumber: '0',
        StairShow: '1',
        isPublic: true,
        Relay: '',
        ArmingFunction: '0',
        IsAllBuild: 0,
        Builds: [],
        ID: '',
        Special: '0',
        NodeID: ''
    });
    const updateToList = ref(0);
    return {
        headers,
        initDeviceData,
        updateToList,
        operaType,
        buildType
    };
};

const getProjectData = () => {
    const communityRequestData: RequestData = {
        url: 'v3/web/community/device/getListForIns',
        param: getSearch({
            ID: (buildType.value === 'all' || buildType.value === 'community') ? 'all' : buildType.value as string,
            searchKey: 'MAC',
            searchValue: '',
            SortField: '',
            Sort: ''
        })
    };
    const officeRequestData: RequestData = {
        url: 'v3/web/office/device/getListForIns',
        param: getSearch({
            ID: (buildType.value === 'all' || buildType.value === 'community') ? 'all' : buildType.value as string,
            searchKey: 'MAC',
            searchValue: ''
        })
    };
    const communitySearchKeyList = buildType.value === 'community' ? [{
        label: WordList.TabelPersonDeviceInHtmlMAC,
        name: 'MAC'
    }, {
        label: WordList.TabelPersonDeviceInHtmlLocation,
        name: 'Location'
    }, {
        label: WordList.MulListUpdateListTanleAccount,
        name: 'SipAccount'
    }, {
        label: WordList.ProperAllTextResident,
        name: 'Owner'
    }] : [{
        label: WordList.TabelPersonDeviceInHtmlMAC,
        name: 'MAC'
    }, {
        label: WordList.TabelPersonDeviceInHtmlLocation,
        name: 'Location'
    }, {
        label: WordList.MulListUpdateListTanleAccount,
        name: 'SipAccount'
    }];
    const officeSearchKeyList = [{
        label: WordList.TabelPersonDeviceInHtmlMAC,
        name: 'MAC'
    }, {
        label: WordList.TabelPersonDeviceInHtmlLocation,
        name: 'Location'
    }, {
        label: WordList.MulListUpdateListTanleAccount,
        name: 'SipAccount'
    }];
    const communityHeader: ListHeaderItem[] = buildType.value === 'community' ? [{
        name: 'MAC',
        label: WordList.TabelPersonDeviceInHtmlMAC
    }, {
        name: 'Location',
        label: WordList.TabelPersonDeviceInHtmlLocation,
        headerType: 'customize'
    }, {
        name: 'Name',
        label: WordList.ProperAllTextResident
    }, {
        name: 'RoomName',
        label: WordList.RDeviceSearchLabelRoomName
    }, {
        name: 'UnitName',
        label: WordList.DeviceDetailDetailUnitName
    }, {
        name: 'Type',
        label: WordList.TabelPersonDeviceInHtmlDeviceTypeLabel,
        type: 'customize'
    }, {
        name: 'SipAccount',
        label: WordList.MulListUpdateListTanleAccount
    }, {
        name: 'Status',
        label: WordList.MulListUpdateListTanleStatus,
        type: 'customize'
    }, {
        name: 'CreateTime',
        label: WordList.TabelMessageBoxCreateTime,
        headerType: 'customize'
    }] : [{
        name: 'MAC',
        label: WordList.TabelPersonDeviceInHtmlMAC
    }, {
        name: 'Location',
        label: WordList.TabelPersonDeviceInHtmlLocation,
        headerType: 'customize'
    }, {
        name: 'Type',
        label: WordList.TabelPersonDeviceInHtmlDeviceTypeLabel,
        type: 'customize'
    }, {
        name: 'SipAccount',
        label: WordList.MulListUpdateListTanleAccount
    }, {
        name: 'Status',
        label: WordList.MulListUpdateListTanleStatus,
        type: 'customize'
    }, {
        name: 'CreateTime',
        label: WordList.TabelMessageBoxCreateTime,
        headerType: 'customize'
    }];
    const officeHeader: ListHeaderItem[] = buildType.value === 'all' ? [{
        name: 'MAC',
        label: WordList.TabelPersonDeviceInHtmlMAC
    }, {
        name: 'Location',
        label: WordList.TabelPersonDeviceInHtmlLocation,
        headerType: 'customize'
    }, {
        name: 'DepartmentName',
        label: WordList.ProperAllTextDepartment
    }, {
        name: 'Name',
        label: WordList.BasicUser
    }, {
        name: 'Type',
        label: WordList.TabelPersonDeviceInHtmlDeviceTypeLabel,
        type: 'customize'
    }, {
        name: 'SipAccount',
        label: WordList.MulListUpdateListTanleAccount
    }, {
        name: 'Status',
        label: WordList.MulListUpdateListTanleStatus,
        type: 'customize'
    }, {
        name: 'CreateTime',
        label: WordList.TabelMessageBoxCreateTime,
        headerType: 'customize'
    }] : [{
        name: 'MAC',
        label: WordList.TabelPersonDeviceInHtmlMAC
    }, {
        name: 'Location',
        label: WordList.TabelPersonDeviceInHtmlLocation,
        headerType: 'customize'
    }, {
        name: 'Type',
        label: WordList.TabelPersonDeviceInHtmlDeviceTypeLabel,
        type: 'customize'
    }, {
        name: 'SipAccount',
        label: WordList.MulListUpdateListTanleAccount
    }, {
        name: 'Status',
        label: WordList.MulListUpdateListTanleStatus,
        type: 'customize'
    }, {
        name: 'CreateTime',
        label: WordList.TabelMessageBoxCreateTime,
        headerType: 'customize'
    }];
    const projectData = {
        community: {
            requestData: communityRequestData,
            searchKeyList: communitySearchKeyList,
            header: communityHeader
        },
        office: {
            requestData: officeRequestData,
            searchKeyList: officeSearchKeyList,
            header: officeHeader
        }
    };
    return projectData;
};

export default null;
export {
    initData,
    DeviceTypeIcon,
    DeviceInfoType,
    getProjectData
};
