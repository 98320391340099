import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, vShow as _vShow, withDirectives as _withDirectives } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")
  const _component_el_form_item = _resolveComponent("el-form-item")
  const _component_cus_option = _resolveComponent("cus-option")
  const _component_cus_select = _resolveComponent("cus-select")
  const _component_el_checkbox = _resolveComponent("el-checkbox")
  const _component_el_radio = _resolveComponent("el-radio")
  const _component_move_build = _resolveComponent("move-build")
  const _component_relay = _resolveComponent("relay")
  const _component_el_form = _resolveComponent("el-form")
  const _component_dialog_shell = _resolveComponent("dialog-shell")

  return (_openBlock(), _createBlock("div", null, [
    _createVNode(_component_dialog_shell, {
      title: _ctx.titles[_ctx.operaType],
      onClose: _ctx.close,
      onSubmit: _ctx.checkValid
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_form, {
          "label-position": _ctx.$formLabelPosition,
          model: _ctx.formData,
          rules: _ctx.rules,
          ref: "deviceEditFormRef"
        }, {
          default: _withCtx(() => [
            (_ctx.identity == 'single')
              ? (_openBlock(), _createBlock(_component_el_form_item, {
                  key: 0,
                  label: _ctx.$wordList.TabelPersonDeviceInHtmlOwner
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_input, {
                      modelValue: _ctx.formData.Node,
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.formData.Node = $event)),
                      disabled: true
                    }, null, 8, ["modelValue"])
                  ]),
                  _: 1
                }, 8, ["label"]))
              : _createCommentVNode("", true),
            _createVNode(_component_el_form_item, {
              label: _ctx.$wordList.TabelPersonDeviceInHtmlDeviceTypeLabel
            }, {
              default: _withCtx(() => [
                _createVNode(_component_cus_select, {
                  modelValue: _ctx.formData.Type,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_ctx.formData.Type = $event)),
                  disabled: _ctx.formData.Special == '1'
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.deviceOptions, (item) => {
                      return (_openBlock(), _createBlock(_component_cus_option, {
                        key: item,
                        value: item.value,
                        label: item.label
                      }, null, 8, ["value", "label"]))
                    }), 128))
                  ]),
                  _: 1
                }, 8, ["modelValue", "disabled"])
              ]),
              _: 1
            }, 8, ["label"]),
            _createVNode(_component_el_form_item, {
              label: _ctx.$wordList.TabelPersonDeviceInHtmlMAC,
              prop: "MAC"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  modelValue: _ctx.formData.MAC,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (_ctx.formData.MAC = $event)),
                  disabled: _ctx.disableMac
                }, null, 8, ["modelValue", "disabled"])
              ]),
              _: 1
            }, 8, ["label"]),
            (_ctx.identity == 'community' || _ctx.identity == 'office')
              ? (_openBlock(), _createBlock(_Fragment, { key: 1 }, [
                  (_ctx.formData.Type == '2')
                    ? (_openBlock(), _createBlock(_component_el_form_item, {
                        key: 0,
                        label: ""
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_el_checkbox, {
                            modelValue: _ctx.formData.ArmingFunction,
                            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (_ctx.formData.ArmingFunction = $event))
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.$wordList.ProperAllTextArmingFunction), 1)
                            ]),
                            _: 1
                          }, 8, ["modelValue"])
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  _createVNode(_component_el_form_item, {
                    label: _ctx.$wordList.TabelDeviceInHtmlNetwork
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_cus_select, {
                        modelValue: _ctx.formData.NetGroupNumber,
                        "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => (_ctx.formData.NetGroupNumber = $event))
                      }, {
                        default: _withCtx(() => [
                          (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.networkOptions, (item, index) => {
                            return (_openBlock(), _createBlock(_component_cus_option, {
                              key: index,
                              value: item,
                              label: `${_ctx.$wordList.ProperAllTextNetwork} ${index + 1}`
                            }, null, 8, ["value", "label"]))
                          }), 128))
                        ]),
                        _: 1
                      }, 8, ["modelValue"])
                    ]),
                    _: 1
                  }, 8, ["label"])
                ], 64))
              : _createCommentVNode("", true),
            (_ctx.showContactSetting)
              ? (_openBlock(), _createBlock(_component_el_form_item, {
                  key: 2,
                  label: _ctx.$wordList.ProperAllTextContactsSetting
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_radio, {
                      class: "display-block margin-top10px",
                      modelValue: _ctx.formData.StairShow,
                      "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => (_ctx.formData.StairShow = $event)),
                      label: "1"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.ChangeWordList.DeviceContactFirstSetting), 1)
                      ]),
                      _: 1
                    }, 8, ["modelValue"]),
                    _createVNode(_component_el_radio, {
                      class: "display-block margin-top15px",
                      modelValue: _ctx.formData.StairShow,
                      "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => (_ctx.formData.StairShow = $event)),
                      label: "3"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.ChangeWordList.DeviceContactSecondSetting), 1)
                      ]),
                      _: 1
                    }, 8, ["modelValue"]),
                    _createVNode(_component_el_radio, {
                      class: "display-block margin-top15px",
                      modelValue: _ctx.formData.StairShow,
                      "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => (_ctx.formData.StairShow = $event)),
                      label: "2"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.ChangeWordList.DeviceContactThirdSetting), 1)
                      ]),
                      _: 1
                    }, 8, ["modelValue"])
                  ]),
                  _: 1
                }, 8, ["label"]))
              : _createCommentVNode("", true),
            _createVNode(_component_el_form_item, {
              label: _ctx.$wordList.TabelPersonDeviceInHtmlLocation,
              prop: "Location"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  modelValue: _ctx.formData.Location,
                  "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => (_ctx.formData.Location = $event))
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }, 8, ["label"]),
            (_ctx.showMoveBuild)
              ? (_openBlock(), _createBlock(_component_el_form_item, {
                  key: 3,
                  label: _ctx.ChangeWordList.DeviceDepartmentOrBuild
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_checkbox, {
                      modelValue: _ctx.formData.IsAllBuild,
                      "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => (_ctx.formData.IsAllBuild = $event))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.ChangeWordList.DeviceAllDepartmentOrBuild), 1)
                      ]),
                      _: 1
                    }, 8, ["modelValue"]),
                    _withDirectives(_createVNode(_component_move_build, {
                      choose: _ctx.chooseBuilds,
                      unchoose: _ctx.unchooseBuilds,
                      onChange: _ctx.changeChooseBuild
                    }, null, 8, ["choose", "unchoose", "onChange"]), [
                      [_vShow, _ctx.formData.IsAllBuild == 0]
                    ])
                  ]),
                  _: 1
                }, 8, ["label"]))
              : _createCommentVNode("", true),
            _withDirectives(_createVNode(_component_relay, {
              relay: _ctx.formData.Relay,
              "security-relay": (_ctx.identity === 'community' && _ctx.isNew || _ctx.identity === 'single') ? _ctx.formData.SecurityRelay : false,
              commit: _ctx.commit,
              onGetRelay: _ctx.submit,
              type: _ctx.formData.Type == '2' ? 'local': 'normal',
              showUnlock: _ctx.identity !== 'single'
            }, null, 8, ["relay", "security-relay", "commit", "onGetRelay", "type", "showUnlock"]), [
              [_vShow, _ctx.singleDeviceType.includes(_ctx.formData.Type)]
            ])
          ]),
          _: 1
        }, 8, ["label-position", "model", "rules"])
      ]),
      _: 1
    }, 8, ["title", "onClose", "onSubmit"])
  ]))
}